import {
  createContext,
  type ReactNode,
  useContext,
  useCallback,
  forwardRef,
  type Ref,
} from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";

import { segmentTrack } from "util/segment";
import Button from "common/core/button";
import { Heading, Substyle } from "common/core/typography";

import Styles from "./index.module.scss";

const AnalyticsPrefix = "New Transaction Page";

type AnalyticEvent =
  | "new-transaction-link"
  | "back-button"
  | "start-over-button"
  | "closing-type"
  | "address-autocomplete"
  | "address-search"
  | "recording-location"
  | "title-agent-search-type"
  | "title-agent-search-email"
  | "title-agent-search-name"
  | "title-agent-manual-select"
  | "title-agent-cleared"
  | "title-agent-add-new"
  | "title-agent-reset-name"
  | "title-agent-lookup-error"
  | "underwriter-question"
  | "underwriter"
  | "continue-button";

const AnalyticEventsMessages: Record<AnalyticEvent, string> = Object.freeze({
  "new-transaction-link": "User clicked new transaction link",
  "back-button": "User clicked back button",
  "start-over-button": "User clicked start over button",
  "closing-type": "User changed closing type",
  "address-autocomplete": "User clicked autocompleted address",
  "address-search": "User manually completed property address form",
  "recording-location": "User changed recording location",
  "title-agent-search-type": "User changed method for searching for title agent",
  "title-agent-search-email": "User started searching for a title agent by email",
  "title-agent-search-name": "User started searching for a title agency by org name or id",
  "title-agent-manual-select": "User manually selected a title agent from dropdown",
  "title-agent-cleared": "User cleared selected title agent from dropdown",
  "title-agent-add-new": "User added a new title agent organization",
  "title-agent-reset-name": "User reset title agent organization name",
  "title-agent-lookup-error": "User encountered title agent lookup error",
  "underwriter-question": "User changed underwriter answer",
  underwriter: "User changed underwriter selection",
  "continue-button": "User created new transaction",
});

type OrgType = "lender" | "title" | null;

const SetupPageAnalyticsContext = createContext<OrgType>(null);

export function TrackNewSetupPageAnalyticsContext({
  children,
  orgType,
}: {
  children: ReactNode;
  orgType: OrgType;
}) {
  return (
    <SetupPageAnalyticsContext.Provider value={orgType}>
      {children}
    </SetupPageAnalyticsContext.Provider>
  );
}

export function useNewSetupPageAnalytics() {
  const context = useContext(SetupPageAnalyticsContext);

  const track = useCallback(
    (event: AnalyticEvent, data?: Record<string, unknown>) => {
      segmentTrack(`${AnalyticsPrefix} - ${AnalyticEventsMessages[event]}`, {
        orgType: context,
        ...data,
      });
    },
    [context],
  );

  return {
    track,
  };
}

type BasicProps = {
  children: ReactNode;
};

export function PageTitle() {
  return (
    <Heading level="h1" textStyle="headingTwo">
      <Substyle textStyle="allCapsLabelSmall" display="block" textColor="subtle">
        <FormattedMessage id="91398763-766b-48db-bcd7-88e4e7fc283f" defaultMessage="Send new" />
      </Substyle>
      <FormattedMessage
        id="7f618b7d-af1d-41e3-9ebc-87cb33a6abae"
        defaultMessage="Real estate closing"
      />
    </Heading>
  );
}

export function Step1Header() {
  return (
    <Heading level="h2" textStyle="headingFive" className={Styles.stepOneHeader}>
      <Substyle textStyle="allCapsLabelSmall" display="block" textColor="subtle">
        <FormattedMessage id="f21643f2-4b74-44d2-a5f5-b57dd4d73947" defaultMessage="Step 1" />
      </Substyle>
      <FormattedMessage
        id="fa9cb051-7526-4a2f-b08e-f21fec9bbf16"
        defaultMessage="Enter your closing details"
      />
    </Heading>
  );
}

function ControlLabel({ children }: BasicProps, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className={Styles.controlLabel}>
      {children}
    </div>
  );
}

const FowardedControlLabel = forwardRef(ControlLabel);
export { FowardedControlLabel as ControlLabel };

export function BackButton() {
  const navigate = useNavigate();
  const { track } = useNewSetupPageAnalytics();

  const location = useLocation();

  return (
    <Button
      className={Styles.backButton}
      onClick={() => {
        track("back-button");
        location.state?.goBackToReferrer ? navigate(-1) : navigate("/");
      }}
      buttonColor="action"
      variant="tertiary"
      buttonSize="large"
      withIcon={{ name: "arrow-left", placement: "left" }}
    >
      <FormattedMessage id="a229483a-3a12-43b8-8989-9dd5d9e85daa" defaultMessage="Back" />
    </Button>
  );
}

export function StartOverButton({ onClick }: { onClick: () => void }) {
  const { track } = useNewSetupPageAnalytics();

  return (
    <div className={Styles.startOverButton}>
      <Button
        onClick={() => {
          track("start-over-button");
          onClick();
        }}
        buttonColor="action"
        variant="tertiary"
        buttonSize="large"
        withIcon={{ name: "arrow-clockwise", placement: "left" }}
      >
        <FormattedMessage id="9e15116a-d28e-4e46-9f03-9565f16a6a5f" defaultMessage="Start over" />
      </Button>
    </div>
  );
}
