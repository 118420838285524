import { type ReactNode, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { Pill } from "common/core/pill_tabs";
import { Card } from "common/core/card";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { MortgageTransactionType } from "graphql_globals";
import {
  PURCHASE_BUYER_CONFIG,
  HYBRID_REFINANCE_CONFIG,
  LENDER_REFINANCE_CONFIG,
  HYBRID_PURCHASE_BUYER_LOAN_CONFIG,
  PURCHASE_BUYER_LOAN_CONFIG,
  PURCHASE_SELLER_CONFIG,
  TITLE_LOAN_MOD_BORROWER_CONFIG,
  LENDER_LOAN_MOD_BORROWER_CONFIG,
  TITLE_HELOC_CONFIG,
  LENDER_HELOC_CONFIG,
} from "common/transaction_creation/v3/config";

import { useNewSetupPageAnalytics, ControlLabel } from "..";
import Styles from "./index.module.scss";

export type ClosingType =
  | MortgageTransactionType.refinance
  | MortgageTransactionType.purchase_seller
  | MortgageTransactionType.purchase_buyer_cash
  | MortgageTransactionType.purchase_buyer_loan
  | MortgageTransactionType.loan_mod_borrower
  | MortgageTransactionType.heloc;

export function supportsHybrid(closingType: MortgageTransactionType) {
  switch (closingType) {
    case MortgageTransactionType.refinance:
    case MortgageTransactionType.purchase_buyer_loan:
    case MortgageTransactionType.purchase_buyer_cash:
    case MortgageTransactionType.purchase_seller:
      return true;
    case MortgageTransactionType.heloc:
    case MortgageTransactionType.loan_mod_borrower:
    case MortgageTransactionType.other:
      return false;
    default:
      return false;
  }
}

export function getMutationParamFromClosingType(closingType: ClosingType, hybrid: boolean) {
  switch (closingType) {
    case MortgageTransactionType.refinance:
      return hybrid ? MortgageTransactionType.hybrid_refinance : MortgageTransactionType.refinance;
    case MortgageTransactionType.purchase_buyer_loan:
      return hybrid
        ? MortgageTransactionType.hybrid_purchase_buyer_loan
        : MortgageTransactionType.purchase_buyer_loan;
    case MortgageTransactionType.purchase_buyer_cash:
      return hybrid
        ? MortgageTransactionType.hybrid_purchase_buyer_cash
        : MortgageTransactionType.purchase_buyer_cash;
    case MortgageTransactionType.purchase_seller:
      return hybrid
        ? MortgageTransactionType.hybrid_purchase_seller
        : MortgageTransactionType.purchase_seller;
    case MortgageTransactionType.heloc:
      return MortgageTransactionType.heloc;
    case MortgageTransactionType.loan_mod_borrower:
      return MortgageTransactionType.loan_mod_borrower;
  }
}

export function getConfigIdFromClosingType(
  closingType: ClosingType,
  hybrid: boolean,
  isTitle: boolean,
) {
  switch (closingType) {
    case MortgageTransactionType.refinance:
      return hybrid ? HYBRID_REFINANCE_CONFIG.id : LENDER_REFINANCE_CONFIG.id;
    case MortgageTransactionType.purchase_buyer_loan:
      return hybrid ? HYBRID_PURCHASE_BUYER_LOAN_CONFIG.id : PURCHASE_BUYER_LOAN_CONFIG.id;
    case MortgageTransactionType.purchase_buyer_cash:
      return PURCHASE_BUYER_CONFIG.id;
    case MortgageTransactionType.purchase_seller:
      return PURCHASE_SELLER_CONFIG.id;
    case MortgageTransactionType.heloc:
      return isTitle ? TITLE_HELOC_CONFIG.id : LENDER_HELOC_CONFIG.id;
    case MortgageTransactionType.loan_mod_borrower:
      return isTitle ? TITLE_LOAN_MOD_BORROWER_CONFIG.id : LENDER_LOAN_MOD_BORROWER_CONFIG.id;
  }
}

const ClosingTypeLabels: Record<ClosingType, ReactNode> = Object.freeze({
  refinance: (
    <FormattedMessage id="a79fc18a-239d-4c3f-ac34-85801b3d08d4" defaultMessage="Refinance" />
  ),
  purchase_buyer_loan: (
    <FormattedMessage id="6eb868bc-7485-4cd4-ba00-090e5d90028a" defaultMessage="Home purchase" />
  ),
  purchase_buyer_cash: (
    <FormattedMessage id="03c85d29-5002-4c80-998b-f300b0be4da1" defaultMessage="Cash buyer" />
  ),
  purchase_seller: (
    <FormattedMessage id="6238dec0-c556-4c62-a971-98ecb0f34403" defaultMessage="Seller" />
  ),
  heloc: <FormattedMessage id="1e4fd5f9-0a4f-4c40-9d74-b35978300436" defaultMessage="HELOC" />,
  loan_mod_borrower: (
    <FormattedMessage
      id="e1b0b0a0-5b9a-4b9a-8b9a-5b9a4b9a8b9a"
      defaultMessage="Loan modification (borrower)"
    />
  ),
});

export function useClosingType() {
  const [closingType, setClosingType] = useState<ClosingType | null>(null);

  const clear = () => {
    setClosingType(null);
  };

  return {
    closingType,
    setClosingType,
    clear,
  };
}

export default function ClosingTypeCard(props: {
  allowedClosingTypes: ClosingType[];
  interaction: ReturnType<typeof useClosingType>;
}) {
  const { allowedClosingTypes, interaction } = props;
  const { closingType, setClosingType } = interaction;
  const { track } = useNewSetupPageAnalytics();
  const [searchParams] = useSearchParams();
  const closingTypeParam = searchParams.get("transaction_type") as ClosingType | undefined;

  const setClosingTypeAndTrack = (closingType: ClosingType) => {
    setClosingType(closingType);
    track("closing-type", {
      closingType,
    });
  };

  useEffect(() => {
    if (!closingTypeParam) {
      return;
    }
    setClosingTypeAndTrack(closingTypeParam);
  }, []);

  return (
    <Card>
      <ControlLabel>
        <FormattedMessage
          id="82a00800-9299-4592-89c7-67d93d7e7302"
          defaultMessage="What type of closing is this?"
        />
        <RequiredAsterisk />
      </ControlLabel>

      <div className={Styles.container}>
        {allowedClosingTypes.map((type) => (
          <Pill
            className={Styles.pillOuter}
            key={`pill-${type}`}
            selected={closingType === type}
            onClick={() => {
              setClosingTypeAndTrack(type);
            }}
            data-automation-id={`closing-type-pill-${type}`}
          >
            {ClosingTypeLabels[type]}
          </Pill>
        ))}
      </div>
    </Card>
  );
}
