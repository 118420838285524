import "./dropdown.scss";

import { useState, memo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Feature } from "graphql_globals";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { NOTARIZE_ACADEMY_URL } from "constants/marketing";
import Apps from "constants/applications";
import { useLogout } from "common/authentication";
import { userFullName } from "util/user";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import Icon from "common/core/icon";
import { redirectUrl } from "util/application_redirect";
import { SETTINGS_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";

type Props = {
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    featureList: Feature[];
    organization: null | {
      name: string | null;
    };
    notaryProfile: null | {
      id: string;
    };
  };
};

function OrganizationNavDropdown({ user }: Props) {
  const { hasPermissionFor } = usePermissions();
  const { organization, email, featureList, notaryProfile } = user;
  const isTitlePortal = CURRENT_PORTAL === Apps.TITLE_AGENCY;
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const logout = useLogout();

  return (
    <div
      className="OrganizationNavDropdown"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={handleClose}
    >
      <span className={SENSITIVE_CLASS} data-automation-id="account-menu-header">
        {userFullName(user) || organization?.name || email || (
          <FormattedMessage id="03965f1f-e965-45f4-a7e9-2db184256c93" defaultMessage="More" />
        )}
      </span>
      <Icon name={`caret-${isOpen ? "up" : "down"}`} />
      {isOpen && (
        <nav className="OrganizationNavDropdown--Menu" onClick={handleClose}>
          <Link to={SETTINGS_PATH} data-automation-id="account-button">
            <FormattedMessage
              id="e67f7fce-7704-4538-8a23-eab8b16d8b0c"
              defaultMessage="Account Settings"
            />
          </Link>
          {hasPermissionFor("templatePreset") && (
            <Link to="/templates/" data-automation-id="templates-menu">
              <FormattedMessage
                id="64a98760-3a82-4ba7-b826-d341ed5c89ed"
                defaultMessage="Templates"
              />
            </Link>
          )}
          {hasPermissionFor("viewOrganizationApiKeys") &&
            featureList.includes(Feature.PARTNER_API) && (
              <Link to="/settings/api" data-automation-id="api-button">
                <FormattedMessage
                  id="c0f45d05-0431-4521-8a35-2e2a67de8477"
                  defaultMessage="API Keys"
                />
              </Link>
            )}
          {isTitlePortal && (
            <a href={NOTARIZE_ACADEMY_URL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage
                id="43ff1549-abe5-4df1-bdb3-dcc8f975fd70"
                defaultMessage="Proof Academy"
              />
            </a>
          )}
          {isTitlePortal && !!notaryProfile && (
            <a
              href={redirectUrl(AppSubdomains.notary)}
              target="_blank"
              rel="noopener noreferrer"
              data-automation-id="notary-portal-button"
            >
              <FormattedMessage
                id="23d44610-6587-421b-b1d9-15cd6692a44f"
                defaultMessage="Go to notary account"
              />
              <Icon name="arrow-up-right-square" className="OrganizationNavDropdown--Menu--Icon" />
            </a>
          )}
          <div className="OrganizationNavDropdown--Menu--Divider" />
          <Link
            to="/logout"
            onClick={(evt) => {
              evt.preventDefault();
              logout();
            }}
            data-automation-id="logout-button"
          >
            <FormattedMessage id="63bac513-0dcf-4940-a3df-078109f690dd" defaultMessage="Log Out" />
          </Link>
        </nav>
      )}
    </div>
  );
}

export default memo(OrganizationNavDropdown);
